main {
  h1,h2,h3 {
    font-family: $font-family-1;
    padding-top: $nav-size; }
  blockquote,
  figure {
    margin: 0;
    p {
      margin: 0; } }
  p {
    padding: 0 $nav-size; } }
