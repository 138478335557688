@font-face {
  font-family: 'Monsieur La Doulaise';
  font-style: normal;
  font-weight: 400;
  src: local('Monsieur La Doulaise Regular'), local('MonsieurLaDoulaise-Regular'), url(../font/MonsieurLaDoulaise-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 200;
  src: local('Source Sans Pro ExtraLight'), local('SourceSansPro-ExtraLight'), url(../font/SourceSansPro-ExtraLight.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  src: local('Source Sans Pro Light'), local('SourceSansPro-Light'), url(../font/SourceSansPro-Light.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro'), local('SourceSansPro-Regular'), url(../font/SourceSansPro-Regular.ttf) format('truetype');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000; }

html {
  background: url('../img/polaroid.png');
  @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    background: url('../img/polaroid_@2x.png');
    size: 50%; }
  font: #{$font-weight} #{$font-percent + "%"}/#{$line-height} #{$font-family-1}; }

body {
  color: $color-font-base;
  font-weight: $font-light;
  min-height: 100vh; }

h1,h2,h3,h4,h5,h6 {
  color: $color-head-base;
  font-family: $font-family-2;
  text-rendering: optimizeLegibility;
  line-height: 1;
  margin-top: 0; }

@include heading-init;

a {
  @include hover-link; }

hr {
  margin: 20px 0; }
