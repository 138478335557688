$font-size: 16px !default;

@function context-calc($scale, $base, $value) {
  @return ($scale/$base)#{$value}; }

@function measure-margin($scale, $measure, $value) {
  $pixelValue: $measure/$scale;
  $remValue: $pixelValue * $font-base;

  @if $value == rem {
    @return $pixelValue#{$value}; }
  @else if $value == em {
    @return ($remValue/$scale)#{$value}; }
  @else {
    @return $remValue#{$value}; } }
