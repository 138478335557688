@include breakpoint($media-tablet) {
  header {
    h1 {
      font-size: 1.7rem;
      margin-top: .4em; } }

  aside {
    vertical-align: top;
    margin-top: $nav-size / 2;
    img {
      width: 45vw;
      padding: 0 $spacing-double; }
    &.left {
      float: left; }
    &.right {
      float: right; } }

  table {
    width: 100%; }

  tr {
    display: table-row;
    width: 100%; }

  td {
    display: table-cell;
    text-align: right;
    width: 50%;
    + td {
      text-align: left; } } }
