footer {
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  font-family: $font-family-1;
  justify-content: space-around;
  align-items: center;

  .copy {
    flex: 1;
    padding-left: 10px; }
  .social {
    padding-right: 10px; } }
