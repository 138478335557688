.nav-collapse {
  padding-top: 10px;
  ul {
    background-color: $blue-arctic;
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%; }
  li {
    display: block;
    width: 100%;
    &:hover {
      background-color: $blue-light-steel; }
    a {
      display: inline-block;
      padding: 6px 12px; } }
  .js & {
    clip: rect(0 0 0 0);
    display: block;
    max-height: 0;
    overflow: hidden;
    position: absolute;
    zoom: 1; }
  &.opened {
    max-height: 9999px; } }

.nav-toggle {
  float: right;
  padding-right: 24px;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none; }

@include breakpoint($media-tablet) {
  .nav-collapse {
    position: relative;
    ul {
      background-color: inherit;
      display: flex;
      margin: 0;
      justify-content: space-around; }
    li {
      padding: 0 10px;
      a {
        background: inherit; } }
    &.closed {
      max-height: none; } }
  .nav-toggle {
    display: none; } }
