// toolkit/reset

// math
// screens

// colors
// borders
// transition

// modular-scale-init
// layout
// icons
// text
// base

/*-------------------------------------*/
// VARIABLES: GLOBAL
/*-------------------------------------*/

/*-------------------------------------*/
// GRID
// Grid: Gutters
$grid-gutter: 10px;

/*-------------------------------------*/
// MEDIA QUERIES

$media-desktop: 960px;
$media-tablet:  720px;
$media-mobile:  480px;

/*-------------------------------------*/
// DIMENSIONS: WIDTHS, HEIGHTS

// Header: Height
$height-header: 45px;
$header-vpad: 16px;
$header-hpad: 24px;

// Nav Menu:
$height-nav: 800px;

// Footer: Height
$height-footer: 100px;

/*-------------------------------------*/
// COLORS

// Colors: Brand
$color-brand-1: #7dcfb8;

// Colors: White
$titanium-white: #fff;
$linen: #f2ebd3;
$bone: #e7dfcc;
$ivory: #fef7e5;
$cotton: #fbfcf7;

// Colors: Common: Greys
$grey-coal: #222;
$grey-gravel: #444;
$grey-gandalf: #aaa;
$grey-newgandalf: #bbb;

// Colors: Common: Cool
$blue-light-steel: 	#b0c4de;
$blue-vivid: #09f;
$blue-arctic: #a0dbe3;

// Colors: Common: Warm
$orange-rust: #8c4005;
$orange-cider: #b66827;
$orange-cantaloupe: #fca172;

// Colors: Brands for Social Media
$blue-facebook: #3b5998;
$blue-twitter: #55acee;
$blue-vimeo: #1ab7ea;
$red-youtube: #cd201f;

// Colors: Links
$color-link: $blue-vivid;
$color-link-hover: darken($color-link, 12%);

// Colors: Base Font
$color-font-base: $grey-gravel;
$color-head-base: $grey-coal;

$color-nav-base: $bone;
$color-nav-sub: $ivory;

/*-------------------------------------*/
// FONTS

// Fonts: Base styles
$font-family-1:  "Source Sans Pro", Helvetica, Arial, sans-serif;
$font-family-2:  "Monsieur La Doulaise", cursive;

$font-weight:    normal;
$line-height:    1.65;
$font-percent:   112.5;
$typescale-unit: rem;

$font-base:      16 * ($font-percent/100);
$measure-base:   $font-base * $line-height;
$font-size:      $font-base * 1px;

$heading-sizes:  (h1: 32, h2: 28, h3: 22, h4: 20, h5: 16, h6: 12);

$nav-size:       16px;

// Fonts: Weights
$font-thin:        100;
$font-extra-light: 200;
$font-light:       300;
$font-normal:      400;
$font-medium:      500;
$font-semi-bold:   600;
$font-bold:        700;
$font-extra-bold:  800;
$font-ultra-bold:  900;

// Fonts: Sizes
$font-sm-x:  80%;
$font-sm:    90%;
$font-lg:   110%;
$font-lg-x: 120%;

// Timings
$timing-fast: 400ms;

/*-------------------------------------*/
// FONT-ICONS: FAMILY

//$font-icon: 'icomoon'

/*-------------------------------------*/
// SPACING

$spacing:        10px;
$spacing-double: $spacing * 2;
$spacing-third:  $spacing * 3;
$spacing-quad:   $spacing * 4;
$spacing-half:   $spacing / 2;

/*-------------------------------------*/
// Z:INDEX

$zindex-header:      1000;
$zindex-dropdown:    1100;
$zindex-toggle:      1200;
$zindex-notify:      1300;
$zindex-modal:       1400;
