header {
  display: inline;
  width: 100%;
  padding: 1.1em 0;
  h1 {
    display:  inline;
    padding-left: 1.1em; }
  a {
    @include unstyled-link; } }

@include breakpoint($media-tablet) {
  header {
    display: flex;
    justify-content: space-between; } }
