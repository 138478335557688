body {
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.full-image {
  width: 100vw;
  object-fit: cover; }

header {
  flex-basis: 20%;
  h1 {
    font-size: 1.2rem; } }

main {
  padding-top: 1.1em;
  flex: 1;
  h1,h2 {
    padding-left: 1.5 * $spacing; } }

aside {
  img {
    width: 100vw;
    object-fit: cover; } }

tr {
  display: block;
  margin-bottom: 20px; }

td {
  display: block;
  text-align: center;
  padding: 0 1em; }

footer {
  align-self: flex-end;
  flex-basis: 10%;
  width: 100vw; }
